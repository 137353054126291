import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  showCustomProjectTypeInput(event) {
    if (event.currentTarget.selectedOptions[0].value == "Custom") {
      this.inputTarget.classList.remove("d-none");
      this.inputTarget.querySelectorAll('input, button, textarea, select').forEach(el => {
        el.removeAttribute("disabled");
      });
    } else {
      this.inputTarget.querySelectorAll('input, button, textarea, select').forEach(el => {
        el.disabled = true;
      });
      this.inputTarget.classList.add("d-none");
    }
  }
}
